import { toRefs, Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesGettersPath, languagesTypes, routerTypes } from '@fwk-client/store/types';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber, CmsBoolean } from '@fwk-client/modules/cms/types';

interface ProductInput {
    type:CmsEnum<"shop"|"hospitality">,
}

export function useProduct(props:ProductInput, {emit}:any) { 
    const app = getApp();
    const $store = useStore();

    const { type } = props;

    const getThumbnailsURLs = (product:any) => {
        let defaultProductShape = product.type == 'ACTIVITY' ? require('@public/img/vigneron-online/activity-shape.jpeg') : require('@public/img/vigneron-online/shape.png');
        let defaultShape = type == "shop" ? defaultProductShape : require('@public/img/vigneron-online/no-photo.jpeg')
        return app.$shop.product.getThumbnailsURLs(product, defaultShape);
    }

    const getPicturesURLs = (product:any) => {
        let defaultProductShape = product.type == 'ACTIVITY' ? require('@public/img/vigneron-online/activity-shape.jpeg') : require('@public/img/vigneron-online/shape.png');
        let defaultShape = type == "shop" ? defaultProductShape : require('@public/img/vigneron-online/no-photo.jpeg')
        return app.$shop.product.getPicturesURLs(product, defaultShape);
    }

    const getViewDetailsPath = (product:any) => {
        var languageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
        if(type == "hospitality") {
            let code = product.apartment.code;
            let path = {
                name : 'hospitality-apartment',
                params : {
                    lang : languageCode,
                    apartmentCode : code
                }
            }
            return path;
        }
        else {
            // We display product details page
            let code = app.$shop_productCodeFromProduct(product);
            let path = {
                name : product.type == 'ACTIVITY' ? 'shop-activity' : 'shop-product',
                params : {
                    lang : languageCode,
                    shopPath : app.$shopContent_path,
                    productCode : code
                }
            }
            return path;
        }
    }

    const getProductImageAlt = (product:any) => {
        return app.$shop.product.getProductTitle(product, app.$shop_shop.name);
    }

  return {
    getThumbnailsURLs,
    getPicturesURLs,
    getViewDetailsPath,
    getProductImageAlt
  }
  
}